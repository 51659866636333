
import './App.css';
import header from '../../images/car-buying-mobile.jpg';

function App() {
  return (
    <div className="App">
      <div className='phone-number'>
        <h1>Call Now <a href='tel:817-807-1878'>817-807-1878</a></h1>
      </div>
      <img src={header} alt='header'/>
      <div className='header-banner'>
       <b>We Pay Cash On The Spot!</b>
      </div>
      <div className='header-text'>
        <p>
        Are you looking to sell your used car with ease and confidence? Say goodbye to the complexities of un-safe online market places or waiting for a low-ball apraisal at the dealer. 
        we specialize in simplifying the process of selling your used car. Simply, give us a call and let us know the year make and model of the vehicle and how much you are looking to sell it for, it's that simple!
        <br />
        <br />
        You don't have to drive your vehicle anywhere, we will come to you and pay you on the spot!
        <br />
        <br />
        Even if you lost the title for your vehicle, <b>WE WILL BUY IT! </b>
        </p>
      </div>

      <div className='faq'>
        <h2 className='faq-heading'>FAQ's</h2><br />
        <b>Q: Do you buy vehicles that don't start? <br />A:</b> Yes!<br /><br />
        <b>Q: What types of payment do you offer? <br />A:</b>  Cash, Check, Venmo, Zelle or CashApp.<br /><br />
        <b>Q: What Make And Models do you buy? <br />A: We buy ALL makes and models.</b><br /><br />
        <b>Q: Do You Come To My House? <br />A: Yes!</b><br /><br />
        <b>Q: Does My Vehicle Need To Be Registered? <br />A: No.</b><br /><br />
        <b>Q: Will you buy my vehicle even if I lost the title? <br />A: Yes!</b><br /><br />
      </div>
      <div className='location'>
        We buy any year make and model's within a 100 mile radius of Dallas Texas.<br />
        GIVE US A CALL TODAY!<br />
        <h1> <a href='tel:817-807-1878'>817-807-1878</a></h1>
      </div>
    </div>
  );
}

export default App;
